<template>
    <v-sheet max-width="1152">
        <template v-if="viewable">
            <div class="py-20px">
                <v-row class="row--xxs" justify="space-between" align="center">
                    <v-col cols="auto">
                        <h4 class="tit tit--xs">{{ board?.subject }}</h4>
                    </v-col>
                    <v-col cols="auto">
                        <div class="divider-group tit--xxs">
                            <ul>
                                <li>{{ board?.createdAt?.toDate?.() }}</li>
                                <li>조회수 {{ board?.viewCount?.format?.() }}회</li>
                                <!-- <li class="d-flex align-center"><v-icon small class="material-icons-outlined mr-4px">thumb_up</v-icon>{{ board?.likeCount?.format?.() }}</li> -->
                                <li class="d-flex align-center"><v-icon small class="material-icons-outlined mr-4px">thumb_up</v-icon>{{ likeCount?.format?.() }}</li>
                            </ul>
                        </div>
                    </v-col>
                </v-row>
                <v-row class="row--xxs">
                    <v-col cols="12">
                        <div class="d-inline-flex align-center cursor-pointer" @click="$router.push(`/minishop/${board?.writer?._id}`)">
                            <v-avatar size="30">
                                <v-img v-if="board.writer?.thumb" :src="board.writer.thumb.url" />
                                <v-img v-else src="/images/minishop/profile-default.png" />
                            </v-avatar>
                            <div class="txt txt--sm ml-6px">
                                {{ board?.writer?.nickname }}
                            </div>
                        </div>
                    </v-col>
                </v-row>
            </div>
            <v-divider />
            <div class="py-20px">
                <story-view-body v-bind="{ board }">
                    <template #foot>
                        <!-- <like-btn :params="{ _board: board?._id }" class="mt-md-8px" @input="search"> -->
                        <like-btn :params="{ _board: board?._id }" @input="setLikeCoount">
                            <template #activator="{ active, toggle }">
                                <v-btn icon rounded outlined color="transparent" class="cursor-pointer" @click.stop.capture="toggle">
                                    <span v-if="active" class="material-icons-outlined accent2--text">thumb_up</span>
                                    <span v-else class="material-icons-outlined primary--text">thumb_up</span>
                                </v-btn>
                            </template>
                        </like-btn>
                        <!-- <span class="font-weight-medium txt txt--sm">{{ board?.likeCount?.format?.() }}</span> -->
                        <span class="font-weight-medium txt txt--sm">{{ likeCount?.format?.() }}</span>
                    </template>
                </story-view-body>
            </div>
            <div class="btn-wrap">
                <v-row class="row--xxs">
                    <v-col cols="auto" v-if="isUserWriter">
                        <v-btn class="v-btn--width-fixed" v-bind="{ ...btn_secondary }" @click="$router.push(`${$route.path}/modify`)">수정</v-btn>
                    </v-col>
                    <v-col cols="auto" v-if="isUserWriter">
                        <v-btn class="v-btn--width-fixed" v-bind="{ ...btn_secondary }" @click="remove">삭제</v-btn>
                    </v-col>
                    <v-spacer />
                    <v-col cols="auto">
                        <v-btn class="v-btn--width-fixed" v-bind="{ ...btn_secondary }" @click="goList" exact>목록</v-btn>
                    </v-col>
                </v-row>
            </div>

            <!-- S: 이전글, 다음글 -->
            <v-row>
                <v-col cols="12">
                    <v-divider style="border-width: 2px 0 0" dark />
                    <v-row class="row--sm ma-0">
                        <v-col cols="auto" class="font-weight-light">
                            <v-icon small>keyboard_arrow_up</v-icon>
                            이전글
                        </v-col>
                        <v-col v-if="prevBoard" class="text-truncate">
                            <router-link :to="showBoard(prevBoard)">{{ prevBoard?.subject }}</router-link>
                        </v-col>
                        <v-col v-else class="text-truncate"> 이전 게시물이 없습니다. </v-col>
                    </v-row>
                    <v-divider />
                    <v-row class="row--sm ma-0">
                        <v-col cols="auto" class="font-weight-light">
                            <v-icon small>keyboard_arrow_down</v-icon>
                            다음글
                        </v-col>
                        <v-col v-if="nextBoard" class="text-truncate">
                            <router-link :to="showBoard(nextBoard)">{{ nextBoard?.subject }}</router-link>
                        </v-col>
                        <v-col v-else class="text-truncate"> 다음 게시물이 없습니다. </v-col>
                    </v-row>
                    <v-divider style="border-width: 2px 0 0" dark />
                </v-col>
                <v-col v-show="!isMinishop && products.length">
                    <story-recommendations @load="syncProducts" />
                </v-col>
            </v-row>
            <!-- E: 이전글, 다음글 -->

            <comments v-bind="{ _board }" />
        </template>
        <template v-else> 권한이 없습니다. </template>
    </v-sheet>
</template>

<script>
import api from "@/api";
import { mapGetters, mapState } from "vuex";
import { initStoryBoard } from "@/store/story";
import { btn_icon, btn_secondary, btn_txt, chip_secondary, COMMON_LOG_TYPES } from "@/assets/variables";
import LikeBtn from "../../like/like-btn.vue";
import StoryViewBody from "./story-view-body.vue";
import StoryPage from "@/components/client/templates/story-page.vue";
import Comments from "@/components/client/board/skin/board-community/comments.vue";
import StoryRecommendations from "@/components/client/sub/story/story-recommendations.vue";

export default {
    components: {
        StoryPage,
        Comments,
        LikeBtn,
        StoryViewBody,
        StoryRecommendations,
    },
    props: {
        code: { type: String },
        _board: { type: String },
    },
    data: () => ({
        board: {},
        prevBoard: {},
        nextBoard: {},

        btn_icon,
        btn_secondary,
        btn_txt,
        chip_secondary,

        loading: false,
        likeCount: 0,
        products: [],
    }),
    computed: {
        ...mapState({
            scope: (state) => state.scope,
            isUserWriter: function (state) {
                return state.payload?._user == this.board.writer?._id;
            },
        }),
        ...mapGetters("story", ["getCategory"]),

        category() {
            return this.getCategory(this.code);
        },
        viewable() {
            if ((this.category?.scope__view?.length || 0) == 0) return true;
            else return this.category?.scope__view?.some?.((item) => (this.scope || []).includes(item));
        },
        basePath() {
            let path = this.$route.path.split("/");
            path.pop();
            return path.join("/");
        },
        isMinishop() {
            return this.$route.path.includes("/minishop");
        },
    },
    methods: {
        async init() {
            if (!this.viewable) return;

            if (this.loading) return;
            else this.loading = true;
            try {
                await this.search();
                //태그 저장
                if (this.$store.state?.payload?._user !== this.board?.writer?._id) {
                    if (this.board?.meta?.tags.length > 0) {
                        await Promise.all(
                            this.board.meta.tags.map(async (value) => {
                                api.v1.shop.logs.saving({ value, type: COMMON_LOG_TYPES.TAG.value, _story: this.board._id });
                            })
                        );
                    }
                }

                this.$emit("input", this.board);
            } finally {
                this.loading = false;
            }
        },
        async search() {
            if (!this.viewable) return;
            try {
                const { prevBoard, board, nextBoard } = await api.v1.boards.get({ _id: this._board });
                this.board = initStoryBoard(board);
                this.prevBoard = prevBoard;
                this.nextBoard = nextBoard;
                this.likeCount = this.board.likeCount;
            } catch (error) {
                this.$handleError(error);
            }
        },
        async remove() {
            if (!this.viewable) return;

            if (this.loading) return;
            else this.loading = true;
            try {
                if (confirm("게시글을 삭제하시겠습니까?")) {
                    let { board } = await api.v1.boards.delete({ _id: this._board });
                    if (board._showcases.length) {
                        await api.v1.showcases.delete({ _id: _showcase });
                        await Promise.all(
                            board._showcases.map(async (_showcase) => {
                                await api.v1.showcases.delete({ _id: _showcase });
                            })
                        );
                    }
                    alert("삭제되었습니다.");
                    this.$router.go(-1);
                }
            } finally {
                this.loading = false;
            }
        },
        setLikeCoount(count) {
            this.likeCount = this.likeCount + count;
        },
        goList() {
            this.$router.push({ path: `/story/${this.code}`, query: this.$route.query });
        },
        showBoard(item) {
            return { path: `/story/${this.code}/${item._id}`, query: this.$route.query };
        },
        syncProducts(products) {
            this.products = products;
        },
    },
    watch: {
        _board() {
            this.init();
        },
    },
    mounted() {
        this.init();
    },
};
</script>

<style lang="scss" scoped>
</style>