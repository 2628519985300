<template>
    <v-sheet>
        <template v-if="isPc">
            <v-tabs v-model="code" grow class="mb-40px mb-lg-60px" @change="push">
                <template v-for="item in items">
                    <tab-primary :tab-value="item.value" :key="item._id">{{ item.text }}</tab-primary>
                </template>
            </v-tabs>
        </template>
        <!-- <template v-if="isMobile">
            <v-select v-model="code" v-bind="{ items }" @change="push" solo  />
        </template> -->
    </v-sheet>
</template>

<script>
import detect from "@/plugins/detect";

import TabPrimary from "@/components/client/tab/tab-pirmary.vue";

export default {
    components: {
        TabPrimary,
    },
    props: {
        value: { type: String, default: "bicycle" },
        items: { type: Array, default: () => [] },
    },
    setup: () => ({
        detect,
    }),
    data: () => ({
        code: undefined,

        initiated: false,
    }),
    computed: {
        isPc() {
            return this.detect == "pc";
        },
        isMobile() {
            return this.detect == "mobile";
        },
    },
    methods: {
        sync() {
            this.code = this.value;
        },
        push() {
            if (this.initiated) this.$router.push({ path: `/story/${this.code}` });
            else this.initiated = true;
        },
    },
    watch: {
        value() {
            this.sync();
        },
    },
    mounted() {
        this.sync();
    },
};
</script>