<template>
    <story-page>
        <v-divider style="border-width: 2px 0 0" dark />
        <story-view v-model="board" v-bind="{ code, _board }" />
    </story-page>
</template>

<script>
import StoryPage from "@/components/client/templates/story-page.vue";
import StoryView from "@/components/client/story/view/story-view.vue";

export default {
    components: {
        StoryPage,
        StoryView,
    },
    props: {
        code: { type: String },
        _board: { type: String },
    },
    data: () => ({
        board: {},
    }),
    methods: {
        reroute() {
            if (!!this.code && !!this.board?.code && !this.board?.code.includes(this.code)) {
                this.$router.replace({ path: `/story/${this.board.code}/${this.board._id}`, query: this.$route.query });
            }
        },
    },
    watch: {
        board() {
            this.reroute();
        },
    },
    mounted() {
        this.reroute();
    },
};
</script>